import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Col, Container, Row } from "react-bootstrap"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Pager from "../components/pager"
import EventTeaser from "../components/eventTeaser"
import BannerAd from "../components/bannerAd"
import BannerBlock from "../components/bannerBlock"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../components/hero"
import HeroSpotlight from "../components/heroSpotlight"
import Breadcrumbs from "../components/breadcrumbs"

const EventListPage = ({ data, pageContext }) => {
  // const { name } = pageContext
  const heroImage = (
    <StaticImage
      src="../images/events-bg.jpg"
      alt="Group of enthusiastic hockey fans wearing Gryphons hats and yellow sweaters, watching a game intently from the stands. The central figure is a young woman smiling, with a soda can in front of her."
    />
  )
  const eventsData = data.events.edges
  // const bannerBlockData = data.blockContentBlockCta.edges[0].node
  // const bannerAdData = data.blockContentBlockAd.edges[0].node
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "event-list-page",
          "aria-label": "Events List",
        }}
      />
      <Seo title={`Events`} keywords={[`gatsby`, `application`, `react`]} />

      {/**** Header and Title ****/}
      <div id="rotator" className="short-hero image">
        <Hero staticImage={heroImage} />
        {/* <div className="container ft-container">
        </div> */}
        <div>
          <HeroSpotlight>
            <h1 className="fancy-title">Upcoming Events</h1>
          </HeroSpotlight>
        </div>
      </div>
      {/**** Body content ****/}
      <div className="page-container">
        <Container>
          <Breadcrumbs targetUrl="/events" activeTitle="Events" />
          <Row className="row-with-vspace site-content card-collection-wrapper aad-standard align-items-stretch">
            {/**** Body content ****/}
            {eventsData.length > 0 ? (
              <>
                {eventsData.map(({ node }, index) => {
                  return (
                    <>
                      <Col xl={3} lg={4} sm={6}>
                        <EventTeaser node={node} />
                      </Col>
                      {/* {index === 2 ? (
                        <Col xl={3} lg={4} sm={6}>
                          <BannerAd block={bannerAdData} />
                        </Col>
                      ) : null} */}
                    </>
                  )
                })}
                {/* {eventsData.length < 2 ? (
                  <Col xl={3} lg={4} sm={6}>
                    <BannerAd block={bannerAdData} />
                  </Col>
                ) : null} */}
              </>
            ) : (
              <p>No events to display.</p>
            )}
          </Row>
          {/* <Pager {...pageContext} /> */}
          {/* <BannerBlock block={bannerBlockData} /> */}
        </Container>
      </div>
    </Layout>
  )
}

EventListPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default EventListPage

export const query = graphql`
  query ($currentDate: Date!, $skip: Int!, $limit: Int!) {
    events: allNodeEvent(
      limit: $limit
      skip: $skip
      filter: { field_date: { end_value: { gte: $currentDate } } }
      sort: { fields: field_date___value, order: ASC }
    ) {
      edges {
        node {
          ...Event
        }
      }
    }
    blockContentBlockAd: allBlockContentBlockAd(filter: { drupal_internal__id: { eq: 1 } }) {
      edges {
        node {
          ...AdBlock
        }
      }
    }
    blockContentBlockCta: allBlockContentBlockCta(filter: { drupal_internal__id: { eq: 2 } }) {
      edges {
        node {
          ...CtaBlock
        }
      }
    }
  }
`
